import React, { Component } from "react"
import Events from "../components/base/events"

class Event extends Component {
  render() {
    return (
      <>
        <Events dashboard={false} />
      </>
    )
  }
}
export default Event
